<template>
    <div v-if="isLoading" class="l-stack l-gap-3 l-padded">
        <VSpinner size="medium" line-fg-color="#000" :speed="1" />
    </div>

    <div v-else class="l-settings-view l-stack l-gap-5 l-padded">
        <div class="l-stack l-gap-2">
            <h3 class="t-title">{{ $t('user_data') }}</h3>
            <FormInput
                v-model="$v.firstName.$model"
                :label="$t('firstName')"
                :required="true"
                :show-required-error="!$v.firstName.required"
            />

            <FormInput
                v-model="$v.lastName.$model"
                :label="$t('lastName')"
                :required="true"
                :show-required-error="!$v.lastName.required"
            />

            <label>
                <div class="form-label l-inline required">
                    {{ $t('language') }}
                </div>

                <VMultiselect
                    v-model="language"
                    :options="languages"
                    label="label"
                    :multiple="false"
                    track-by="value"
                    :show-labels="false"
                    :close-on-select="true"
                    :hide-selected="true"
                    :searchable="false"
                    open-direction="below"
                />
            </label>
        </div>

        <div class="l-stack l-gap-2">
            <h3 class="t-title">{{ $t('notifications') }}</h3>
            <FormMultiInput
                v-model="$v.emailRecipients.$model"
                :label="$t('emailRecipients')"
                placeholder="mail@host.ch"
            >
                <template slot="error">
                    <div
                        v-if="
                            $v.emailRecipients.$anyError &&
                                $v.emailRecipients.$dirty
                        "
                        class="form-error"
                    >
                        {{ $t('notValidEmail') }}
                    </div>
                </template>
            </FormMultiInput>

            <FormMultiInput
                v-model="$v.smsRecipients.$model"
                :label="$t('smsRecipient')"
                :info-text="$t('smsDisclaimer')"
                placeholder="+41 79 123 45 67"
            >
                <template slot="error">
                    <div
                        v-if="
                            $v.smsRecipients.$anyError &&
                                $v.smsRecipients.$dirty
                        "
                        class="form-error"
                    >
                        {{ $t('notValidPhone') }}
                    </div>

                    <div v-if="serverErrors.sms_recipients">
                        <div
                            v-for="(error,
                            key,
                            index) in serverErrors.sms_recipients"
                            :key="index"
                            class="form-error"
                        >
                            <template v-for="e in error">{{ e }}</template>
                        </div>
                    </div>
                </template>
            </FormMultiInput>
        </div>

        <div v-if="$v.$anyError" class="form-error">{{ $t('saveError') }}</div>

        <FormNavigation @save="save" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required, helpers, email } from 'vuelidate/lib/validators'
import VMultiselect from 'vue-multiselect'
import VSpinner from 'vue-simple-spinner'

import { httpHelper } from '@/utils'
import FormInput from '../FormInput'
import FormMultiInput from '../FormMultiInput'
import FormNavigation from '../FormNavigation'

const phoneNumber = helpers.regex('phone', /^\+[0-9\s]+$/)

export default {
    name: 'SettingsView',
    components: {
        FormInput,
        FormMultiInput,
        FormNavigation,
        VMultiselect,
        VSpinner,
    },
    props: {},
    data() {
        return {
            languages: [
                { label: this.$t('shared.language.de'), value: 'de' },
                { label: this.$t('shared.language.en'), value: 'en' },
                { label: this.$t('shared.language.fr'), value: 'fr' },
                { label: this.$t('shared.language.it'), value: 'it' },
            ],
            language: null,

            firstName: '',
            lastName: '',
            smsRecipients: '',
            emailRecipients: [],

            isLoading: true,
            serverErrors: {},
        }
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUserId']),
    },
    validations() {
        return {
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            // use as soon as api is ready
            emailRecipients: {
                $each: {
                    email,
                },
            },
            smsRecipients: {
                $each: {
                    phoneNumber,
                },
            },
        }
    },
    async mounted() {
        this.isLoading = true
        await this.loadData()
        this.isLoading = false
    },
    methods: {
        ...mapActions('auth', ['loadUserInfo']),
        async loadData() {
            const [
                { data: userData },
                { data: alertSettings },
            ] = await Promise.all([
                httpHelper.get(`/users/${this.getCurrentUserId}/`),
                httpHelper.get(
                    `/users/${this.getCurrentUserId}/alertsettings/`
                ),
            ])

            this.firstName = userData.first_name
            this.lastName = userData.last_name
            this.language = this.languages.find(
                ({ value }) => value === userData.language
            )

            this.emailRecipients = alertSettings.email_recipients || []
            this.smsRecipients = alertSettings.sms_recipients || []
        },
        async saveData() {
            this.serverErrors = {}

            return Promise.all([
                httpHelper.patch(`/users/${this.getCurrentUserId}/`, {
                    first_name: this.firstName,
                    last_name: this.lastName,
                    language: this.language.value,
                }),
                httpHelper.put(
                    `/users/${this.getCurrentUserId}/alertsettings/`,
                    {
                        user: this.getCurrentUserId,
                        enabled: true,
                        sms_recipients: this.smsRecipients,
                        email_recipients: this.emailRecipients,
                    }
                ),
            ]).catch(error => {
                console.log(error)
                this.serverErrors = Object.assign(
                    this.serverErrors,
                    error.response.data
                )
                this.isLoading = false
                return Promise.reject()
            })
            /*
                await .catch((error) => {
                    console.log(error.response)
                    this.serverErrors = Object.assign(this.serverErrors, error.response.data)
                });

                await .catch((error) => {
                    console.log(error.response)
                    this.serverErrors = Object.assign(this.serverErrors, error.response.data)
                });
*/
        },
        async save() {
            if (this.isValid()) {
                this.isLoading = true

                await this.saveData()
                await this.loadUserInfo()

                this.isLoading = false

                this.$router.push('/map', () => {
                    this.$router.go()
                })
            }
        },
        isValid() {
            this.$v.$touch()
            return !this.$v.$invalid
        },
    },
}
</script>

<i18n>
{
    "en": {
        "user_data": "User",
        "firstName": "First Name",
        "lastName": "Last Name",
        "language": "Language",
        "notifications": "Notifications",
        "smsRecipient": "Send SMS notifications to this numbers",
        "smsDisclaimer": "Additional charges are incurred when using this service.",
        "emailRecipients": "Send notifications to these e-mail addresses",
        "emailRecipientsInfo": "Multiple email addresses can be separated by commas.",
        "saveError": "Not all required fields are correctly filled out.",
        "notValidPhone": "This phone number is invalid. Please enter an international number starting with, e.g., +41.",
        "notValidEmail": "Not all the e-mail-addresses are valid"
    },
    "de": {
        "user_data": "Benutzer",
        "firstName": "Vorname",
        "lastName": "Nachname",
        "language": "Sprache",
        "notifications": "Benachrichtigungen",
        "smsRecipient": "Nummern für SMS Benachrichtigungen",
        "smsDisclaimer": "Für die Nutzung dieser Dienstleistung fallen zusätzliche Kosten an.",
        "emailRecipients": "Benachrichtigungen an diese E-Mail Adressen schicken",
        "emailRecipientsInfo": "Mehrere E-Mail Adressen können kommasepariert erfasst werden.",
        "saveError": "Es sind nicht alle benötigten Felder korrekt ausgefüllt.",
        "notValidPhone": "Diese Telefonnummer ist ungültig. Bitte international mit z.B. +41 eingeben.",
        "notValidEmail": "Nicht jede eingegebene E-Mail-Adresse ist gültig."
    },
    "fr": {
        "user_data": "Utilisateur",
        "firstName": "Prénom",
        "lastName": "Nom",
        "language": "Langue",
        "notifications": "Notifications",
        "smsRecipient": "Envoyer un SMS à ces numéros",
        "smsDisclaimer": "Des frais supplémentaires sont encourus lors de l'utilisation de ce service.",
        "emailRecipients": "Envoyer des notifications à ces adresses e-mail",
        "emailRecipientsInfo": "Plusieurs adresses e-mail peuvent être séparées par des virgules.",
        "saveError": "Certains champs ne sont pas remplis correctement.",
        "notValidPhone": "Ce numéro de téléphone n'est pas valide. Veuillez saisir un numéro international commençant par +41 par exemple.",
        "notValidEmail": "Certaines adresses e-mail ne sont pas valides"
    },
    "it": {
        "user_data": "Utente",
        "firstName": "Prenome",
        "lastName": "Cognome",
        "language": "Lingue",
        "notifications": "Notificazione",
        "smsRecipient": "Invia SMS al seguente numeri SMS",
        "smsDisclaimer": "Ci sono costi aggiuntivi per l'utilizzo di questo servizio.",
        "emailRecipients": "Invia notifiche al seguente indirizzi Email",
        "emailRecipientsInfo": "Mehrere E-Mail Adressen können kommasepariert erfasst werden.",
        "saveError": "Es sind nicht alle benötigten Felder korrekt ausgefüllt.",
        "notValidPhone": "Diese Telefonnummer ist ungültig. Bitte international mit z.B. +41 eingeben.",
        "notValidEmail": "Nicht jede eingegebene E-Mail-Adresse ist gültig."
    }
}
</i18n>
